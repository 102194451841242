<template>
	<div id='app'>
        <div style="display:flex;justify-content:space-between;margin-right:8px;margin-top:8px;">
            <!-- <el-input style="width:400px;" v-model="key" placeholder="请输入关键字"></el-input> -->
            <div>
                <el-select  v-model="station" @change="changeKey" filterable 
                    :filter-method="pinyingMatch" placeholder="车站"  style="width: 200px" 
                    clearable               
                    @clear="clearStat"
                    >
                    <el-option :label="item.label" :value="item.value" v-for="(item,index) in copyStationList" :key="item.value"></el-option>
                </el-select>
                
                <el-button type="primary" style="margin-left:15px;" @click="onSearch()">检索</el-button>  
             </div>
             <div>
                <el-switch  v-model="isCityAnd"  active-text="地区与"  inactive-text="地区或" @change="onSearch"></el-switch>
            </div>
        </div>

        <div class='nav' v-for='(items1,index1) in tagList' :key="index1">
            <div v-if="showAll ||(!showAll && items1.showIndex )" style="display:flex;margin-top:8px;" :class="{on:index1%2==0,off:index1%2!=0}">
                <div style="width:10%;">
                    <span  :class="{'active':items1.Active} " style="font-weight: bolder;font-size: 18px;margin-left:8px;cursor:pointer;" @click='handle(items1)' :key='items1.Id'>{{items1.Name}}</span>
                </div>
                <div style="display:flex;width:90%;flex-wrap:wrap;" >
                    <div v-if="items1.SubTag"  v-for='(items2,index2) in items1.SubTag'  :key="index2">
                        <span v-if="items2.SubTag && items2.SubTag.length>0" >
                            <span style="cursor:pointer;">
                                <span  style="margin-left:20px;margin-top:3px;"
                                     :class="{'active':items2.Active} "  @click='handleLevel2(items2,1)' :key='items2.Id'>{{items2.Name}}</span>
                                <span v-if="items2.SubTag.length>0">
                                    <i v-if="items2.Expand===false"  @click='handleLevel2(items2,2)' class="el-icon-arrow-down" ></i>
                                    <span v-else >
                                        <i class="el-icon-arrow-up"  @click='handleLevel2(items2,2)'></i>
                                        <div style="display:flex;">
                                            <div class='nav' v-for='(items3,index3) in items2.SubTag' :key="index3">                                                
                                                <div style="margin-left:20px;margin-top:3px;cursor:pointer;" :class="{'active':items3.Active}" @click='handle(items3)' :key='items3.Id'>{{items3.Name}}</div>
                                            </div>
                                        </div>
                                    </span>
                                </span>
                            </span>                            
                        </span>
                        <div v-else  style="display:flex;flex-wrap:wrap;">
                            <span  style="margin-left:20px;margin-top:3px;cursor:pointer;" :class="{'active':items2.Active}" @click='handle(items2)' :key='items2.Id'>{{items2.Name}}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div style="display:flex;justify-content:flex-end;margin-right:20px;">
            <el-button type="text" style="margin-left:15px;" @click="showAll=!showAll"> {{showAll?'收起':'高级'}}</el-button> 
        </div>
        
    </div>
</template>

<script>
// import Vue from 'vue';
import { mapGetters } from "vuex";
// import {pinyingMatch} from "pinyin-match";
import PinyinMatch from "pinyin-match"

export default {
		name: "Search",
		props: ["type"],
        computed: {...mapGetters(['GetMediaTagList','GetTagGroupList','stationList'])},
		watch: {			
		},
        data(){
            return {  
                list:{              
                    category:[
                            {
                                name:'品牌',
                                items:[
                                {
                                    name:'联想',
                                    active: false
                                },
                                {
                                    name:'小米',
                                    active: false
                                },
                                {
                                    name:'苹果',
                                    active: false
                                },
                                {
                                    name:'东芝',
                                    active: false
                                }
                                ]
                            },
                            {
                                name:'CPU',
                                items:[
                                {
                                    name:'intel i7 8700K',
                                    active: false
                                },
                                {
                                    name:'intel i7 7700K',
                                    active: false
                                },
                                {
                                    name:'intel i9 9270K',
                                    active: false
                                },
                                {
                                    name:'intel i7 8700',
                                    active: false
                                },
                                {
                                    name:'AMD 1600X',
                                    active: false
                                
                                }
                                ]
                            },
                            {
                                name:'内存',
                                items:[
                                {
                                    name:'七彩虹8G',
                                    active: false
                                },
                                {
                                    name:'七彩虹16G',
                                    active: false
                                },
                                {
                                    name:'金士顿8G',
                                    active: false
                                },
                                {
                                    name:'金士顿16G',
                                    active: false
                                }
                                ]
                            },
                            {
                                name:'显卡',
                                items:[
                                {
                                    name:'NVIDIA 1060 8G',
                                    active: false
                                },
                                {
                                    name:'NVIDIA 1080Ti 16G',
                                    active: false
                                },
                                {
                                    name:'NVIDIA 1080 8G',
                                    active: false
                                },
                                {
                                    name:'NVIDIA 1060Ti 16G',
                                    active: false
                                }
                                ]
                            }
                        ],
                    condition:[]      
                },
                tagList:[],  
                selList:[],   
                selList_Level2:[],  
                key:'',
                station:'',
                isCityAnd:false,
                copyStationList:[],
                showAll:false,
            }
        },
		created() {			
		},
        async mounted() {
            if (this.type=="1"){
                // console.log('1')
                await this.$store.dispatch('operate/stationList').then(()=>{
                    this.copyStationList = this.stationList
                });
                await this.$store.dispatch('operate/GetMediaTagList').then(()=>{
                    this.tagList = this.GetMediaTagList;
                    // console.log('GetMediaTagList',this.GetMediaTagList)
                });
            }else{
                // console.log('2')
                await this.$store.dispatch('operate/GetTagGroupList').then(()=>{
                    this.tagList = this.GetTagGroupList;
                    // console.log('GetMediaTagList',this.GetMediaTagList)
                });
            }
            this.$emit("setfinished");
        },
		
	methods: {
        onSearch(){
            var obj=[];
            this.selList_Level2.forEach(ele => {
                var ids = ele.level3Ids;
                // console.log('do onSearch',ids)
                ids = (ids.substring(0,1) == ',') ? ids.substring(1, ids.length) : ids;
                console.log('do onSearch',ids)
                obj.push(ids)
            });
            this.$emit("onchange",obj.join(";"),this.key,this.station,this.isCityAnd);
        },

        handleLevel2(item,flag){
            if (flag==2){
                if (item.SubTag.length>0){
                    item.Expand=!item.Expand;
                }else{
                    this.handle(item);
                }
            }else{
                item.Active=!item.Active;
                this.delLevel2List(item.Id,item.SubTag,item.Active);
                    // console.log('kao')
                    this.onSearch();
               
                
            }
        },

        handle(item){            
            item.Active=!item.Active;
            if(item.level==0){
                this.delLevel1List(item.SubTag,item.Active)
            }else if(item.level==1){
                this.delLevel2List(item.Id,item.SubTag,item.Active)
            }else{
                this.delLevel3List(item,item.Active);
            }

            // console.log('aa',this.selList_Level2);

            // item.Active=!item.Active;
            // if (item.SubTag && item.SubTag.length>0){
            //     // console.log('you sub')
            //     item.SubTag.forEach(e1 => {
            //         e1.Active=item.Active;
            //         if (e1.SubTag && e1.SubTag.length>0){
            //             e1.SubTag.forEach(e2 => {
            //                 e2.Active=item.Active;
            //                 this.dealSelList(e2.Id,e2.description,e2.flags,item.Active);
            //             });
            //         }else{
            //             this.dealSelList(e1.Id,e1.description,e1.flags,item.Active);
            //         }
            //     });
            // }else{
            //     this.dealSelList(item.Id,item.description,item.flags,item.Active);
            // }
            // var obj={};
            // var tids="";
            // var mArr=[],fArr=[];
            // this.selList.forEach(ele => {
            //     if (tids==""){
            //         tids=ele.Id+'';                   
            //     }else{
            //         tids=tids+','+ele.Id;                    
            //     }
            //      mArr.push(ele.description);
            //      if (ele.flags!==""){
            //          var f=ele.flags.split('^');
            //          fArr=fArr.concat(f);
            //      }
            // });
            // obj.tagIds = tids;
            // obj.flags=[...new Set(fArr)]

            // // fArr.push("bb1");
            // // fArr.push("bb2");
            // obj.memos=mArr;
            // // obj.flags=fArr;
            this.onSearch();
            
        },

        //items 为第二级数据集合
        delLevel1List(items,selected){
            console.log('do level1')
            items.forEach(e2 => {
                e2.Active=selected;
                let index = this.selList_Level2.findIndex(val => val.Id === e2.Id);
                if (index !== -1){
                    this.selList_Level2.splice(index, 1);
                }
                e2.SubTag.forEach(e3 => {
                    e3.Active=selected;
                });

                if(selected){
                    var ids='';
                    if(e2.SubTag.length>0){
                        e2.SubTag.forEach(e3 => {
                            ids=ids+','+e3.Id;
                        });
                    }else{
                        ids=e2.Id;
                    }
                    this.selList_Level2.push({Id:e2.Id,level3Ids:ids})
                }
            });
        },

        //Id 为当前第二级选中项的Id，items 为第三级数据集合
        delLevel2List(Id,items,selected){
            // console.log('do level2',Id)
            let index = this.selList_Level2.findIndex(val => val.Id === Id);
            if (index !== -1){
                this.selList_Level2.splice(index, 1);
            }
            items.forEach(e3 => {
                e3.Active=selected;
            });
            if (selected){
                var ids='';
                if(items.length>0){
                    items.forEach(e3 => {
                        ids=ids+','+e3.Id;
                    });
                }else{
                    ids=','+Id;
                }
                // console.log('do level2',ids)
                this.selList_Level2.push({Id,level3Ids:ids})
            }
        },

        //item 为当前第三级的选中项
        delLevel3List(item,selected){
            console.log('do level3',selected,this.selList_Level2)
            let index = this.selList_Level2.findIndex(val => val.Id === item.ParentId);
            if (index !== -1){
                var ids=this.selList_Level2[index].level3Ids+',';
                ids=ids.replace(","+item.Id+",",",");
                ids = (ids.substring(ids.length - 1) == ',') ? ids.substring(0, ids.length - 1) : ids;
                if (selected){
                    ids=ids+','+item.Id;
                }
                this.selList_Level2[index].level3Ids=ids;
            }else{
                if (selected){
                    this.selList_Level2.push({Id:item.ParentId,level3Ids:','+item.Id})
                }
            }
        },



        dealSelList(Id,description,flags,selected){
            // console.log('bb',Id,selected)
            let index = this.selList.findIndex(val => val.Id === Id);
            if (selected==true){
                if (index == -1){
                    var obj ={Id,description,flags}
                    this.selList.push(obj)
                }
            }else{                
                if (index !== -1){
                    this.selList.splice(index, 1);
                }
            }
        },

        pinyingMatch(val){
		    // const pinyingMatch = require('pinyin-match')
            // const PinyinMatch = require('pinyin-match');
            console.log('aaa',val)
		    if(val){
		        var result = []
		        this.stationList.forEach( e => {
                    var m = PinyinMatch.match(e.label, val)
                    if( m){
                        result.push(e)
                    }
		        })
		        //搜索到相应的数据就把符合条件的n个数据赋值brand 
		        this.copyStationList = result
		    }else{
		           //没有搜索到数据，就还展示所有的brand 
		        this.copyStationList = this.stationList
		    }
		},

        clearStat(){
            this.copyStationList = this.stationList
        },
	
	}
}
</script>

<style scoped>
.active{
    color: red;
}
.haveLevel3{
    display:flex;
    flex-wrap: wrap;
}
.on{

}
.off{
    background: rgb(226, 226, 226);
}
.level1Title{
    font-weight: bolder;
}
.link{
    cursor: pointer;
}
</style>